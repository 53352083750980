<template>
  <div class="warningTotal">
    <!-- 预警统计 -->
    <div class="searchBtn">
      <el-select v-model="searchInfo.planId" placeholder="请选择测评计划" @focus="getPlanList" size="small" clearable>
        <el-option v-for="item in PlanList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
      <el-select v-model="gaugeId" placeholder="请选择量表" size="small" @change="getList">
        <el-option v-for="item in GaugeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="searchInfo.dimensionId" placeholder="请选择维度" size="small" @focus="getDimensionsList" clearable>
        <el-option v-for="item in DimensionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
      <el-select v-model="searchInfo.level" placeholder="请选择预警等级" size="small" @focus="judgeGauge" clearable>
        <el-option label="一级" :value="1"></el-option>
        <el-option label="二级" :value="2"></el-option>
        <el-option label="三级" :value="3"></el-option>
        <!-- <el-option label="否" :value="0"></el-option>
        <el-option label="是" :value="1"></el-option> -->
      </el-select>
      <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" @focus="getOrganizationsList" size="small"
        clearable placeholder="请选择组织机构" :show-all-levels="false"
        :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>

      <el-select v-model="searchInfo.roleId" placeholder="请选择角色" size="small" @focus="getRolesList" clearable>
        <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      
      <div class="bottom">
        <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small" @focus="judgeGauge">
      <el-option label="全部" :value="4"></el-option>
        <el-option label="男" :value="1"></el-option>
        <el-option label="女" :value="2"></el-option>
        <el-option label="未填写" :value="3"></el-option>
        
      </el-select>
        <el-input placeholder="开始年龄" v-model="searchInfo.startAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')" style="margin-right:10px" @focus="judgeGauge"></el-input>
        <span>至</span>
        <el-input placeholder="结束年龄" v-model="searchInfo.endAge" type="number" size="small" clearable
          oninput="value=value.replace(/[^0-9.]/g,'')" @focus="judgeGauge"></el-input>
        <el-date-picker size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间" @focus="judgeGauge"
          style="margin-right:10px" format="yyyy-MM-dd" value-format="yyyy-MM-dd 00:00:00">
        </el-date-picker>
        <span>至</span>
        <el-date-picker size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间" @focus="judgeGauge"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
        <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" @focus="judgeGauge" size="small" clearable>
        </el-input>
        <el-button type="primary" size="small" @click="search">查询</el-button>
      </div>

    </div>
    <div class="tableContent">
      <div class="top"><span>{{ gaugeName }}预警测评记录</span>
      <el-dropdown split-button type="primary" @command="handleCommand" size="small">
          导出数据
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">维度得分</el-dropdown-item>
            <el-dropdown-item command="2">答题数据</el-dropdown-item>
            <el-dropdown-item command="3">答题记录</el-dropdown-item>
            <el-dropdown-item command="4">人员列表</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown split-button type="primary" size="small" @command="handleCommandReport">
          导出报告
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">测评报告</el-dropdown-item>
            <el-dropdown-item command="2">预警报告</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button type="primary" size="small">导出数据</el-button>
        <el-button type="primary" size="small">导出报告</el-button> -->
      </div>
      <div class="table">
        <el-table :data="tableData" border @selection-change="handleSelectionChange" v-loading="tableLoading" style="width: 100%">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column prop="userId" label="编号" width="80" fixed>
          </el-table-column>
          <el-table-column prop="username" label="登录名" width="120" fixed>
          </el-table-column>
          <el-table-column prop="nickname" label="姓名" width="120" fixed >
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="80" fixed>
            <template slot-scope="scope">
              <span v-if="scope.row.sex === 1">男</span>
              <span v-else-if="scope.row.sex === 2">女</span>
              <span v-else-if="scope.row.sex === 3">未填写</span>
            </template>
          </el-table-column>
          <el-table-column prop="deptName" label="组织机构" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="roleName" label="角色" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="planName" label="测评计划名称" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="gaugeName" label="量表名称" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="dimensionName" label="维度名称" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header" >
              <span>预警级别</span>
              <el-tooltip class="item" effect="dark" content="如果触发多个预警级别，只显示最高的预警级别。" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span v-if="scope.row.level === 1">一级</span>
              <span v-else-if="scope.row.level === 2">二级</span>
              <span v-else-if="scope.row.level === 3">三级</span>

            </template>
          </el-table-column>
          <el-table-column prop="submitTime" label="提交时间" width="160">
          </el-table-column>
          <el-table-column prop="time" label="答题时长" width="100">
          </el-table-column>
          <!-- <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="lookWarning(scope.row)">预警报告</el-button>
              <el-button type="text" size="small" @click="lookView(scope.row.userAnswerId)">测评报告</el-button>
              <el-button type="text" size="small" @click="lookAnswer(scope.row.userAnswerId)">答题记录</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
            layout="total, prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 基本数据统计 -->
    <div class="numTotal" v-show="gaugeId !== null">
      <span>基本数据统计</span>
      <div class="table" v-loading="loading">
        <el-table :data="numData" style="width: 100%" border size="small">
          <el-table-column label="量表名称">
            <template>
              <span>{{ gaugeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="预警样本数" prop="warningCount">
            <!-- <template slot-scope="scope">
              <span>{{scope.row.warningCount}}</span>
            </template> -->
          </el-table-column>
          <el-table-column label="总样本数" prop="totalCount">
          </el-table-column>
          <el-table-column prop="proportion" label="预警比例">
          </el-table-column>
        </el-table>
      </div>
      <div style="display:flex;box-sizing: border-box;">
        <div class="icon" id="icon" v-loading="loading"></div>
        <span class="download" @click="printOut">下载图片</span>
      </div>
    </div>
    <!-- 维度数据统计 -->
    <div class="dimensionTotal" v-show="gaugeId !== null">
      <span>维度数据统计</span>
      <div class="table" v-loading="dimensionLoading">
        <el-table :data="dimensionData" style="width: 100%" row-key="id" border 
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" @selection-change="handleSelectionChange">
          <el-table-column label="维度名称" prop="name" show-overflow-tooltip width="300" fixed>
          </el-table-column>
          <el-table-column label="预警名称" prop="warningName" show-overflow-tooltip width="300" fixed>
          </el-table-column>
          <el-table-column label="预警级别">
            <template slot-scope="scope">
              <span v-if="scope.row.level === 1">一级</span>
              <span v-else-if="scope.row.level === 2">二级</span>
              <span v-else-if="scope.row.level === 3">三级</span>

            </template>
          </el-table-column>
          <el-table-column prop="address" label="预警范围" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.minRange }}</span>
              <span v-if="scope.row.firstSymbol === 1">＜</span>
              <span v-else-if="scope.row.firstSymbol === 2">≤</span>
              <span v-if="scope.row.firstSymbol"> X </span>
              <span v-if="scope.row.firstSymbol === 1">＜</span>
              <span v-else-if="scope.row.firstSymbol === 2">≤</span>
              <span>{{ scope.row.maxRange }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="gaugeWarningCount" label="预警样本数">
          </el-table-column>
          <el-table-column prop="warning" label="占总预警比例">
            <!-- <template slot-scope="scope">
              <span>1200</span>
            </template> -->
          </el-table-column>
          <el-table-column prop="total" label="占总样本比例">
          </el-table-column>
        </el-table>
      </div>
      <div class="echartsIcon">
        <div class="itemIcon" v-for="(item, index) in dimensionData" v-loading="dimensionLoading" :key="item.id">
          <span>{{ item.name }} -预警统计</span><span
            style="display:inline-block;margin-left: 20px; color: #27AEA1; cursor: pointer;"
            @click="downloadTotal(item, index)">下载图片</span>
          <div :id="'itemIcon' + index" :key="item.name" class="imgIcon"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { getGaugeList } from '@/api/gauge/gaugeList.js'
import { getDimensionList, getProjectList } from '@/api/gauge/dimension'
import { EvaluationWarningList, getPlansList, getNumList, getRatioList, getWarningReport } from '@/api/dangerWarning/warning'
export default {
  data() {
    return {
      tableLoading:false,
      loading: false,
      dimensionLoading: false,
      // 顶部搜索的内容
      searchInfo: {
        deptId: null, // 组织机构id
        dimensionId: null, // 维度id
        roleId: null, // 角色id
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        nickname: null, // 姓名
        startAge: null, // 开始年龄
        sex: null, // 性别
        planId: null, // 计划id
        level: null, // 预警等级
        endAge: null // 结束年龄
      },
      // 当前选择的量表id
      gaugeId: null,
      // 当前量表的名称
      gaugeName: '',
      // 角色数组
      RolesList: null,
      // 组织机构数组
      OrganizationsList: null,
      // 预警记录数据
      tableData: [],
      // 基础数据数组
      numData: [],
      // 预警记录分页数据
      paging: {
        p: 1,
        ps: 16,
        total: 0,
      },
      // 量表数组
      GaugeList: [],
      // 维度数组
      DimensionList: [],
      // 预警列表
      WarningList: [],
      PlanList: [],
      dimensionData: [], // 维度统计
      selectList: [] // 用户点击选择的数组

    }
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      if (this.gaugeId !== null) {
        const res = await getOrganizationList()
        console.log('组织机构');
        console.log(res);
        if (res.code === 200) {
          this.OrganizationsList = res.data;
          // this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
        }
      } else {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
     // 用户点击导出报告
     async handleCommandReport(val) {
      // 1.预警报告 2. 测评报告
      if (this.selectList.length > 0) {
        let arr = [];
        this.selectList.forEach(item => {
          arr.push(item.userAnswerId);
        });
        
        console.log( 'type',val)
        let list = arr.join(',');
        console.log('ids', list)
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/warning/export/report?ids=${list}&type=${val}`;
        // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '请选择测评记录！'
        });
      }

    },
    // 获取角色列表
    async getRolesList() {
      if (this.gaugeId !== null) {
        const res = await getRoleList();
        console.log(res);
        if (res.code === 200) {
          this.RolesList = res.data;
          // this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
        }
      } else {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
    // 点击分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getList();
    },
    // 获取量表列表
    async getGaugesList() {
      const res = await getGaugeList({ p: 1, ps: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.GaugeList = res.data.records;
      }
    },
    // 获取计划数组
    async getPlanList() {
      if (this.gaugeId !== null) {
        const res = await getPlansList({ id: this.gaugeId });
        console.log(res);
        if (res.code === 200) {
          this.PlanList = res.data;
        }
      } else {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }

    },
    //  根据量表id获取维度
    async getDimensionsList() {
      if (this.gaugeId !== null) {
        this.getProjects();
        const res = await getDimensionList({ id: this.gaugeId });
        console.log(res)
        if (res.code === 200) {
          this.DimensionList = [...this.DimensionList,...res.data]
        }
      } else {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
     // 获取项目数列表
     async getProjects() {
      const res = await getProjectList(this.gaugeId);
      console.log(res);
      if(res.code === 200) {
        this.DimensionList = [...this.DimensionList,...res.data]
      }

    },
    // 判断是否选择了量表
    judgeGauge() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      }
    },
    // 点击搜索
    search() {
      if (this.gaugeId === null) {
        this.$message({
          showClose: true,
          message: '请先选择量表！'
        });
      } else {
        // console.log(Number(new Date(this.searchInfo.startTime)));
        // console.log(Number(new Date(this.searchInfo.endTime)));
        if (Number(new Date(this.searchInfo.startTime)) > Number(new Date(this.searchInfo.endTime))  && this.searchInfo.startTime !== null && this.searchInfo.endTime !== null) {
          this.$message({
            showClose: true,
            message: '开始时间不能大于结束时间！'
          });
        } else if (Number(this.searchInfo.startAge) > Number(this.searchInfo.endAge) && this.searchInfo.startTime !== null && this.searchInfo.endTime !== null) {
          this.$message({
            showClose: true,
            message: '开始年龄不能大于结束年龄！'
          });
        } else {
          this.getList();
        }

      }
    },
    // 获取预警记录列表
    async getList() {
      // console.log(this.gaugeId);
      this.tableLoading = true;
      // 修改当前页面显示的量表名称
      this.GaugeList.forEach(item => {
        if (item.id === this.gaugeId) {
          this.gaugeName = item.name
        }
      })

      this.getNumberList();
      this.getDimension();
      let info = { ...this.searchInfo, gaugeId: this.gaugeId, p: this.paging.p, ps: this.paging.ps };
      if(info.sex === 4) {
        info.sex = null;
      }
      // console.log(info);
      const res = await EvaluationWarningList(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.tableLoading = false;
        this.tableData = res.data.warningListVOList;
        this.paging.total = res.data.count;
        // if (res.data.records.length > 0) {
        //   this.gaugeName = res.data.records[0].gaugeName;
        // }
      }
    },
    // 获取基本数据列表
    async getNumberList() {
      this.loading = true;
      let info = { ...this.searchInfo, gaugeId: this.gaugeId };
      // console.log(info)
      const res = await getNumList(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.loading = false;
        this.numData = [{ ...res.data }];
        this.echartsInit(res.data);
      }
    },
    // 获取维度数据统计
    async getDimension() {
      this.dimensionLoading = true;
      const res = await getRatioList(JSON.stringify({ gaugeId: this.gaugeId, ...this.searchInfo }));
      console.log(res);
      if (res.code === 200) {
        this.dimensionLoading = false;
        this.dimensionData = res.data;
        // 提取饼状图需要的数据
        this.dimensionData.forEach((item, index) => {
          // 渲染图形
          this.echartsIcon(item, index);
          // console.log(item)
        })
      }
    },
    // 用户点击导出
    async handleCommand(val) {
      // console.log(val)
      // console.log(this.selectList);
      // let arr = [];
      // this.selectList.forEach(item => {
      //   arr.push(item.userAnswerId);
      // });
      // let list = arr.join(',');
      // const elink = document.createElement('a');
      // elink.href = `http://101.34.136.224:8080/warning/export?ids=${list}&type=${val}`;
      // elink.click();
       if (this.selectList.length > 0) {
        let arr = [];
        this.selectList.forEach(item => {
          arr.push(item.userAnswerId);
        });
        let list = arr.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/warning/export?ids=${list}&type=${val}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '请选择测评记录！'
        });
      }
    },
    // 用户点击选择
    handleSelectionChange(val) {
      this.selectList = val;
    },
    echartsInit(val) {
      // console.log(this.xList);
      let option = {
        tooltip: {},
        xAxis: {

        },
        yAxis: { data: ['总样本数', '预警样本数'] },
        series: [{
          name: '分值',
          type: 'bar',
          data: [val.totalCount, val.warningCount],
          color: '#5b87a3'
        }]
      };
      let myEchart = this.$echarts.init(document.getElementById("icon"));

      myEchart.setOption(option);
    },
    echartsIcon(val, val2) {
      // console.log(val)
      this.$nextTick(() => {
        // console.log(this.xList);
        let option = {
          series: [
            {
              type: 'pie',
              data: [
                {
                  value: val.gaugeWarningCount,
                  name: '预警样本数'
                },
                {
                  value: val.otherWarning,
                  name: '其他预警样本数'
                },
                {
                  value: val.noWarningCount,
                  name: '未预警样本数'
                }
              ],

              // name: ['预警样本数','其他预警样本数','未预警样本数'],
            },

          ],
          tooltip: {
            trigger: 'item',
            formatter: "<br/>{b}: {c} ({d}%)"//模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。
          },
        };


        let myEchart = this.$echarts.init(document.getElementById(`itemIcon${val2}`));

        myEchart.setOption(option);
      })
    },
    // 实现导出页面为图片
    // ---------------分割线-------------------
    dataURLToBlob(dataurl) {//ie 图片转格式
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime })
    },
    downloadResult(name) {
      let canvasID = document.getElementById('icon');
      let a = document.createElement('a');
      html2canvas(canvasID).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", name + ".png")
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    // 点击下载
    printOut() {
      let name = '基本数据统计'
      // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
      // $(window).scrollTop(0); // jQuery 的方法
      document.body.scrollTop = 0; // IE的
      document.documentElement.scrollTop = 0; // 其他
      this.downloadResult(name)
    },
    // ---------------分割线-------------------
    dataURLToBlob1(dataurl) {//ie 图片转格式
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime })
    },
    downloadResult1(name, index) {
      let canvasID = document.getElementById(`itemIcon${index}`);
      let a = document.createElement('a');
      html2canvas(canvasID).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob1(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", name + ".png")
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    downloadTotal(val, index) {
      // console.log(val);
      let name = `${val.name} -预警统计`
      // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
      // $(window).scrollTop(0); // jQuery 的方法
      document.body.scrollTop = 0; // IE的
      document.documentElement.scrollTop = 0; // 其他
      this.downloadResult1(name, index)
    }
  },
  created() {
    this.getGaugesList();

    // this.getOrganizationsList();
    // this.getRolesList();
  },
  mounted() {
    this.$message({
          showClose: true,
          message: '请先选择量表'
      });
  }
}
</script>

<style lang="scss" scoped>
.warningTotal {
  padding: 20px;

  // width: 100%;
  // overflow-x: hidden;
  .searchBtn {
    .bottom {
      margin-top: 20px;

      ::v-deep.el-date-editor {
        width: 150px !important;
      }

      span {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  .tableContent {
    .top {

      span,
      .el-button {
        display: inline-block;
        margin-right: 10px;
        margin-left: 0px;
      }
       .el-dropdown{
        margin-right: 10px;
      }
    }

    .block {
      margin-top: 20px;
    }
  }

  .numTotal {
    .icon {
      height: 200px;
      width: 80%;
    }

  }

  .dimensionTotal {
    .echartsIcon {
      display: flex;
      flex-wrap: wrap;


      .itemIcon {
        margin-top: 40px;
        height: 300px;
        width: 350px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .imgIcon {
          height: 200px;
          width: 320px;
        }
      }
    }
  }

  .tableContent,
  .numTotal,
  .dimensionTotal {
    margin-top: 40px;

    .table {
      margin-top: 10px;
    }
  }

  .download {
    display: inline-block;
    padding-top: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #27AEA1;
    cursor: pointer;
  }
}
</style>